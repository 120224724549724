<template>
  <div
    class="tl-player-tooltip e-border-solid mb-2"
    :class="{
      'e-bg-gray-900 e-text-white e-border-gray-700': $vuetify.theme.dark,
      'e-bg-white e-text-gray e-border-gray-300': !$vuetify.theme.dark,
    }"
  >
    <!-- Line graph tooltip -->
    <div v-if="counts && counts.length && event" class="pa-4 mb-2">
      <div v-if="event.startDate && event.endDate">
        <div><strong>From</strong>: {{ event.startDate }}</div>
        <div><strong>To</strong>: {{ event.endDate }}</div>
      </div>
      <div v-else-if="event.timestamp">
        {{ event.timestamp }}
      </div>
      <div v-for="(c, i) in counts" :key="i">
        <span>{{ c.label }}</span>
        <strong class="pl-2">{{ c.count }}</strong>
      </div>
    </div>

    <!-- Gate report / motion event tooltip -->
    <TimelinePlayerGateReportTooltip
      v-else-if="event && active && ['gateReport', 'motion'].includes(type)"
      :event="event"
      :timezone="timezone"
      :token="token"
    />

    <!-- ANPR event tooltip -->
    <TimelinePlayerImageTooltip
      v-else-if="event && active && ['anpr'].includes(type)"
      :clickable="false"
      :resize="false"
      large
      :item="anprItem"
      :width="220"
    />

    <!-- Timelapse report tooltip -->
    <div
      v-else-if="
        event && active && /siteActivity|safety|construction/.test(type)
      "
      class="tl-player-tl-report-tooltip pa-3"
    >
      <strong class="py-2">{{ event.text }}</strong>
      <div>{{ event.description }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { TimelineEvent } from "@evercam/ui"
import TimelinePlayerGateReportTooltip from "@evercam/shared/components/timelinePlayer/tooltips/TimelinePlayerGateReportTooltip"
import TimelinePlayerImageTooltip from "@evercam/shared/components/timelinePlayer/TimelinePlayerImageTooltip"

export default Vue.extend({
  components: {
    TimelinePlayerImageTooltip,
    TimelinePlayerGateReportTooltip,
  },
  props: {
    counts: {
      type: Array as PropType<{ count: number; type: string }[]>,
      default: () => [],
    },
    event: {
      type: Object as PropType<TimelineEvent>,
      default: () => ({}),
    },
    type: {
      type: String as PropType<"gateReport" | "anpr" | "motion" | string>,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    token: {
      type: String,
      default: "",
    },
  },
  computed: {
    anprItem() {
      return {
        label: `Plate: ${this.event.label}`,
        timestamp: `Time: ${this.$moment
          .tz(this.event.timestamp, this.timezone)
          .format("YYYY-MM-DD HH:mm:ss")}`,
        src: this.event.thumbnailUrl,
      }
    },
  },
})
</script>

<style>
.tl-player-tooltip {
  transform: translateX(-50%);
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: 1px 2px 10px -2px #000;
  .tl-player-tl-report-tooltip {
    max-width: 600px;
  }
}
</style>
