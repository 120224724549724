<template>
  <TimelinePlayer
    dark
    :project="project"
    :height="playerHeight"
    hide-camera-selector
    :token="accountStore.token"
    :with-player-progress-bar="false"
    :is-playing="isPlaying"
    :events-groups-config="timelineEventsGroupsConfig"
    :player-start="siteAnalyticsStore.snapshotsInterval.from"
    :player-end="siteAnalyticsStore.snapshotsInterval.to"
    :selected-camera="siteAnalyticsStore.selectedCamera"
    :selected-timestamp="siteAnalyticsStore.selectedTimestamp"
    :from-date="siteAnalyticsStore.timelineFromDate"
    :to-date="siteAnalyticsStore.timelineToDate"
    :e-timeline-props="eTimelineProps"
    :refresh-breakpoints="refreshBreakpoints"
    :precision-breakpoints="precisionBreakpoints"
    :chart-type-by-precision="chartTypeByPrecision"
    @precision-change="currentPrecision = $event"
    @update-playback="isPlaying = $event"
    @seek="siteAnalyticsStore.selectTimestamp"
    @timeline-interval-change="onTimelineIntervalChange"
    @timestamp-change="refreshBoundingBoxes"
  >
    <template #imageOverlay="{ image }">
      <div class="h-100 w-100">
        <div
          ref="container"
          class="w-100 h-100 e-top-0 position-absolute d-flex align-center justify-center"
        >
          <div v-if="!isSegmentsMode" class="position-relative w-100 h-100">
            <BoundingBox
              v-for="(item, i) in boundingBoxes"
              :key="item.label + i"
              :label="item.label"
              :x-min="item.bbox[0]"
              :y-min="item.bbox[1]"
              :x-max="item.bbox[2]"
              :y-max="item.bbox[3]"
            />
          </div>
          <svg
            v-else-if="segmentMasks.length && image"
            class="masks-container h-100 w-100"
          >
            <SegmentPolygon
              v-for="(item, i) in segmentMasks"
              :key="item.label + i"
              :label="item.label"
              :points="item.mask"
              :image-width="image.width"
              :image-height="image.height"
            />
          </svg>
        </div>
      </div>
    </template>

    <template #top-left>
      <div
        v-if="!isCranesMode"
        class="site-analytics__labels d-flex flex-wrap mt-6"
      >
        <DetectionLabelChip
          v-for="label in playerOverlayLabels"
          :key="label"
          :label="label"
          dark
          @mouseenter.native="onLabelMouseenter(label)"
          @mouseleave.native="onLabelMouseleave(label)"
        />
      </div>
    </template>

    <template #top-right>
      <div class="site-analytics__controls px-2 mt-3 mr-3">
        <EToggleSwitch
          v-model="siteAnalyticsStore.selectedMode"
          :options="modeOptions"
          class="w-100"
          size="Sm"
          color="primary"
        />
        <v-checkbox
          v-show="isSegmentsMode"
          v-model="isDetailedMode"
          label="Breakdown by label"
          dense
          dark
          hide-details
          class="checkbox pt-0 e-bg-gray-900 e-rounded-md"
        />
      </div>
    </template>

    <template v-if="!isCranesMode" #eventTooltip="{ active, type }">
      <DetectionLabelChip v-if="active" :label="type" dark />
    </template>
  </TimelinePlayer>
</template>

<script lang="ts">
import Vue from "vue"
import TimelinePlayer from "@evercam/shared/components/timelinePlayer/TimelinePlayer.vue"
import DetectionLabelChip from "@evercam/shared/components/siteAnalytics/DetectionLabelChip.vue"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import {
  DetectionLabel,
  Project,
  TimelinePlayerConfig,
  TimelinePrecision,
} from "@evercam/shared/types"
import {
  camelToKebabCase,
  debounce,
  stringToColour,
} from "@evercam/shared/utils"
import { TimelineCraneActivityProvider } from "@evercam/shared/components/timelinePlayer/providers/timelineCraneActivityProvider"
import {
  DAY,
  HOUR,
  TimelineColors,
  TLPlayerDefaultPrecisionBreakpoints,
  TLPlayerDefaultRefreshBreakpoints,
} from "@evercam/shared/constants/timeline"
import BoundingBox from "@evercam/shared/components/BoundingBox"
import SegmentPolygon from "@evercam/shared/components/SegmentPolygon"
import { SiteAnalyticsMode } from "@evercam/shared/types/siteAnalytics"
import { TimelineObjectDetectionIntervalsProvider } from "@evercam/shared/components/timelinePlayer/providers/timelineObjectDetectionProvider"
import { TimelineChartType } from "@evercam/ui"
import { camelize } from "humps"
import { FREQUENCY_TO_SECONDS_MAP } from "~/components/constants"
import { TimelineSegmentsIntervalsProvider } from "@evercam/shared/components/timelinePlayer/providers/timelineSegmentsProvider"

export default Vue.extend({
  name: "SiteAnalyticsPlayer",
  components: {
    BoundingBox,
    SegmentPolygon,
    TimelinePlayer,
    DetectionLabelChip,
  },
  async asyncData({ params }) {
    const cameraExid = params.camera_exid
    const siteAnalyticsStore = useSiteAnalyticsStore()

    if (cameraExid !== siteAnalyticsStore.selectedCamera?.exid) {
      await siteAnalyticsStore.selectCamera(cameraExid)
    }
  },
  data() {
    return {
      isPlaying: false,
      isDetailedMode: false,
      currentPrecision: TimelinePrecision,
      boundingBoxes: [],
      segmentsIntervalsByLabel: {},
      segmentsProvidesByLabel: {},
      colors: TimelineColors,
      refreshBreakpoints: TLPlayerDefaultRefreshBreakpoints.map((bp) => {
        if (bp.precision === TimelinePrecision.Events) {
          return {
            precision: TimelinePrecision.Minute,
            breakpoint: HOUR / 2,
          }
        }

        return bp
      }),
      precisionBreakpoints: [
        ...TLPlayerDefaultPrecisionBreakpoints.slice(0, -1),
        {
          precision: TimelinePrecision.Minute,
          breakpoint: DAY / 2,
        },
        {
          precision: TimelinePrecision.Minute,
          breakpoint: 0,
        },
      ],
      modeOptions: [
        { label: "Detections", value: SiteAnalyticsMode.Detections },
        { label: "Segments", value: SiteAnalyticsMode.Segments },
      ],
      highlightedLabel: null,
      playerOverlayLabels: [],
      SiteAnalyticsMode,
    }
  },
  head() {
    return {
      title: `Site Analytics | ${
        useSiteAnalyticsStore().selectedCamera?.name || "Evercam"
      }`,
    }
  },
  computed: {
    ...mapStores(useSiteAnalyticsStore, useAccountStore),
    isCranesMode(): boolean {
      return this.siteAnalyticsStore.selectedMode === SiteAnalyticsMode.Cranes
    },
    isSegmentsMode(): boolean {
      return this.siteAnalyticsStore.selectedMode === SiteAnalyticsMode.Segments
    },
    playerHeight() {
      return window.innerHeight
    },
    project(): Project {
      return {
        startedAt: this.siteAnalyticsStore.selectedCamera?.createdAt,
        timezone: this.siteAnalyticsStore.selectedCamera?.timezone,
        cameras: [this.siteAnalyticsStore.selectedCamera],
      } as Project
    },
    timelineEventsGroupsConfig(): TimelinePlayerConfig {
      if (this.isCranesMode) {
        return this.craneActivityEventsGroups
      } else if (this.isSegmentsMode) {
        return this.segmentsIntervalsEventsGroups
      } else {
        return this.detectionsIntervalsEventsGroups
      }
    },
    craneActivityEventsGroups(): TimelinePlayerConfig {
      return ["Crane-A", "Crane-B", "Crane-C"].reduce((acc, label) => {
        return {
          ...acc,
          [label]: {
            label,
            color: this.colors[label],
            provider: new TimelineCraneActivityProvider({
              cameraExid: this.siteAnalyticsStore.selectedCameraExid,
              timezone: this.siteAnalyticsStore.selectedCamera
                .timezone as string,
              craneLabelFilterFn: (l) =>
                this.getCraneNameByTrackingId(Number(l)) === label,
            }),
          },
        }
      }, {})
    },
    detectionsIntervalsEventsGroups(): TimelinePlayerConfig {
      return Object.keys(DetectionLabel).reduce((acc, label) => {
        return {
          ...acc,
          [label]: {
            label,
            color: this.colors[label] ?? stringToColour(label),
            provider: new TimelineObjectDetectionIntervalsProvider({
              cameraExid: this.siteAnalyticsStore.selectedCameraExid,
              timezone: this.siteAnalyticsStore.selectedCamera
                .timezone as string,
              labelFilterFn: (l) =>
                [label, camelToKebabCase(label)].includes(l),
            }),
          },
        }
      }, {})
    },
    segmentsIntervalsEventsGroups(): TimelinePlayerConfig {
      if (!this.isDetailedMode) {
        return {
          segments: {
            label: "Segments",
            color: this.colors.anpr,
            provider: this.getOrCreateSegmentsProvider("all", true),
          },
        }
      }

      const labels = Object.keys(this.segmentsIntervalsByLabel).sort((a, b) =>
        a.length > b.length ? -1 : 1
      )

      return labels.reduce((acc, label) => {
        return {
          ...acc,
          [label]: {
            label,
            color: stringToColour(label),
            provider: this.getOrCreateSegmentsProvider(label),
          },
        }
      }, {})
    },
    eTimelineProps(): Record<string, unknown> {
      if (this.isCranesMode) {
        return { chartMinHeight: 100 }
      }

      let height = 5
      let padding = 0

      if (this.isSegmentsMode) {
        height = this.isDetailedMode ? 1 : 10
        padding = this.isDetailedMode ? 0 : 30
      }

      return {
        barHeight: height,
        showLabels: false,
        minChartHeight: 50,
        barYPadding: padding,
      }
    },
    chartTypeByPrecision(): Record<TimelinePrecision, TimelineChartType> {
      return Object.values(TimelinePrecision).reduce((acc, precision) => {
        return {
          ...acc,
          [precision]: this.isCranesMode
            ? TimelineChartType.LineGraph
            : TimelineChartType.Bars,
        }
      }, {})
    },
    timePerSnapshot(): number {
      return (
        FREQUENCY_TO_SECONDS_MAP[
          this.siteAnalyticsStore.selectedCamera?.cloudRecordingFrequency
        ] * 1000
      )
    },
    segmentMasks(): { label: string; mask: number[][] }[] {
      return Object.entries(this.siteAnalyticsStore.segmentsByLabel).reduce(
        (acc, [label, segments]) => {
          return acc.concat(segments.map((segment) => ({ label, ...segment })))
        },
        []
      )
    },
  },
  watch: {
    highlightedLabel(label) {
      const eventsGroups = Array.from(document.querySelectorAll(".event-group"))
      if (!label) {
        eventsGroups.forEach((el) => {
          el.style.filter = "none"
        })
      } else {
        eventsGroups.forEach((el) => {
          el.style.filter = el.classList.contains(
            `event-group-${camelize(label)}`
          )
            ? "opacity(1)"
            : "opacity(0.2)"
        })
      }
    },
    "siteAnalyticsStore.trackingsByLabel"() {
      this.refreshBoundingBoxes(this.siteAnalyticsStore.selectedTimestamp)
    },
    "siteAnalyticsStore.selectedMode": {
      immediate: true,
      handler() {
        this.siteAnalyticsStore.selectTimestamp(
          this.siteAnalyticsStore.selectedTimestamp
        )
      },
    },
  },
  async mounted() {
    TimelineObjectDetectionIntervalsProvider.registerIntervalsChangeCallback(
      this.updateAvailableDetectionsLabels
    )
    TimelineSegmentsIntervalsProvider.registerIntervalsChangeCallback(
      this.updateAvailableSegmentationLabels
    )
    await this.fetchInitialSegmentsIntervals()
  },
  methods: {
    onTimelineIntervalChange: debounce(function ({ fromDate, toDate }) {
      this.siteAnalyticsStore.timelineFromDate = fromDate
      this.siteAnalyticsStore.timelineToDate = toDate
    }, 500),
    findNearestDetections(timestamp) {
      let trackings = []
      if (this.isCranesMode) {
        trackings =
          this.siteAnalyticsStore.trackingsByLabel[DetectionLabel.TowerCrane]
      } else {
        trackings = Object.values(
          this.siteAnalyticsStore.trackingsByLabel
        ).flat()
      }

      if (!trackings?.length) {
        return []
      }
      const timestampMs = new Date(timestamp).getTime()
      let filteredDetections = []

      let minTimeDifference = this.timePerSnapshot
      let nearestDetections = []
      for (const item of trackings) {
        const labelName = this.isCranesMode
          ? this.getCraneNameByTrackingId(item.trackId)
          : item.label

        for (const detection of item.detections) {
          const timeDifference = Math.abs(
            new Date(detection.timestamp).getTime() - timestampMs
          )
          if (timeDifference <= minTimeDifference) {
            minTimeDifference = timeDifference
            nearestDetections.push({
              trackId: item.trackId,
              label: labelName,
              originalLabel: item.label,
              bbox: detection.bbox,
              color: this.colors[labelName],
              timeDifference,
            })
          }
        }
      }

      filteredDetections = nearestDetections.filter(
        ({ timeDifference }) => timeDifference === minTimeDifference
      )

      return filteredDetections
    },
    getCraneNameByTrackingId(trackId) {
      const craneName = {
        1: "Crane-A",
        0: "Crane-B",
        2: "Crane-C",
      }

      return craneName[trackId]
    },
    refreshBoundingBoxes(t) {
      if (Object.keys(this.siteAnalyticsStore.trackingsByLabel).length === 0) {
        return
      }
      this.boundingBoxes = this.findNearestDetections(t)
    },
    onLabelMouseenter(label) {
      this.highlightedLabel = label
    },
    onLabelMouseleave(label) {
      this.$setTimeout(() => {
        if (this.highlightedLabel === label) {
          this.highlightedLabel = null
        }
      }, 200)
    },
    updateAvailableSegmentationLabels: debounce(function (intervalsByLabel) {
      const newLabels = Object.keys(intervalsByLabel)
      const oldLabels = Object.keys(this.segmentsIntervalsByLabel)
      const isNewLabelsSet =
        newLabels.length !== oldLabels.length ||
        !newLabels.every((label) => oldLabels.includes(label))
      if (isNewLabelsSet) {
        this.segmentsIntervalsByLabel = intervalsByLabel
      }

      if (this.siteAnalyticsStore.selectedMode !== SiteAnalyticsMode.Segments) {
        return
      }

      this.playerOverlayLabels = Object.keys(intervalsByLabel).sort((a, b) =>
        a.length > b.length ? -1 : 1
      )
    }, 500),
    updateAvailableDetectionsLabels(intervalsByLabel) {
      if (
        this.siteAnalyticsStore.selectedMode !== SiteAnalyticsMode.Detections
      ) {
        return
      }

      this.playerOverlayLabels = Object.keys(intervalsByLabel).sort((a, b) =>
        a.length > b.length ? -1 : 1
      )
    },
    getOrCreateSegmentsProvider(label, groupedMode = false) {
      if (!this.segmentsProvidesByLabel[label]) {
        this.segmentsProvidesByLabel[label] =
          new TimelineSegmentsIntervalsProvider({
            cameraExid: this.siteAnalyticsStore.selectedCameraExid,
            timezone: this.siteAnalyticsStore.selectedCamera.timezone as string,
            labelFilterFn: (l) => l === label,
            groupedMode,
          })
      }

      return this.segmentsProvidesByLabel[label]
    },
    async fetchInitialSegmentsIntervals() {
      const provider = this.getOrCreateSegmentsProvider("stub")
      await provider.fetchEvents({
        fromDate: this.siteAnalyticsStore.timelineFromDate,
        toDate: this.siteAnalyticsStore.timelineToDate,
        precision: this.currentPrecision,
      })
    },
  },
})
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";
@import "~@evercam/shared/styles/mixins";

.bounding-box {
  &__label {
    top: 0;
    left: 0.5rem;
  }
  &:not(.bounding-box--active) {
    transition: none !important;
  }
}
.site-analytics__labels {
  align-items: flex-start;
  align-content: baseline;
  height: 65vh;
  width: 120px;
  overflow: auto;
  overflow-x: hidden;
  @include custom-scrollbar(#5d5d5d, #9b9b9b, #333);
  @include fading-scroll-overflow(
    $container-height: 65vh !important,
    $mask-height-top: -32px
  );
  .detection-label {
    margin: 0.1em 0.2em;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
.event-group {
  transition: filter 0.1s;
}
</style>
